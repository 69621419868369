import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import React from 'react'

const MaritimeFooterInfoCard = (props) => {
  const { title, icon, wider = false } = props

  if (!title && !icon) {
    return null
  }

  return (
    <UILayout
      column
      margin="10px"
      maxWidth={wider ? '285px' : '260px'}
      sm={{ maxWidth: 'auto', width: '47%' }}
      xs={{ margin: '10px 10px 30px 10px', width: '100%' }}
    >
      <UILayout>
        {icon ? (
          <UIIcon type={icon} margin="0 0 30px 0" xs={{ margin: '0 0 10px 0' }} />
        ) : (
          <UIText size="22px" font="ADPortsGroupBold, sans-serif" margin="0 0 30px 0" xs={{ margin: '0 0 10px 0' }}>
            {title}
          </UIText>
        )}
      </UILayout>
      {props.children}
    </UILayout>
  )
}

export default MaritimeFooterInfoCard
