import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { Details } from '../../../components'
import messages from '../../../consts/messages'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'

const Value = styled.span`
  font-size: 18px;
`

const DifferenceDetails = styled(Details)`
  font-size: 20px;
  font-weight: ${getStyle('700')};
  @media (max-width: 600px) {
    font-size: ${getStyle('16px', '12px')};
  }
`

const DifferenceValue = styled.span`
  color: #b42a2a;
  font-family: ${getStyle('Mulish, Sans-Serif', 'GinesoNormDem, Fjalla One, sans-serif')};
  font-size: ${getStyle('24px', '28px')};
  font-weight: ${getStyle('700')};

  ${({ refundable }) =>
    refundable &&
    `
    color: ${getStyle('#218F21', '#63853e')};
  `}
`

class DifferenceToPayView extends PureComponent {
  render() {
    const {
      paymentInfo: {
        paidAmount: oldTotal,
        totalPrice: { amount: newTotal },
      },
    } = this.props

    const differenceToPay = this.getDifferenceToPay()

    return (
      <div>
        <Details stretched>
          <Details.Item label={messages.oldTotal} color={getStyle('#000000')}>
            <Value>{this.formatValue(oldTotal)}</Value>
          </Details.Item>

          <Details.Item label={messages.newTotal} color={getStyle('#000000')}>
            <Value>{this.formatValue(newTotal)}</Value>
          </Details.Item>
        </Details>

        <DifferenceDetails stretched>
          <Details.Item label={messages.difToPay} color={getStyle('#000000')}>
            <DifferenceValue refundable={differenceToPay <= 0}>{this.formatValue(differenceToPay)}</DifferenceValue>
          </Details.Item>
        </DifferenceDetails>
      </div>
    )
  }

  getDifferenceToPay() {
    const {
      paymentInfo: { toPayAmount, toRefundAmount },
    } = this.props

    if (typeof toRefundAmount !== 'undefined' && toRefundAmount !== 0) {
      return -toRefundAmount
    }

    if (typeof toPayAmount !== 'undefined' && toPayAmount !== 0) {
      return toPayAmount
    }

    return 0
  }

  formatValue(value) {
    const {
      paymentInfo: {
        totalPrice: { currency },
      },
      intl,
    } = this.props

    return intl.formatNumber(value, {
      currency,
      minimumFractionDigits: 2,
      style: 'currency',
    })
  }
}

export { DifferenceToPayView }
