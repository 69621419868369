import { UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import { getLocale } from '../../../../services/user-selections'

class InfoPopup extends React.PureComponent {
  render() {
    const { title, description, top, left, xsTop, xsLeft, locale } = this.props
    const isArabic = locale === 'ar'

    const position = isArabic ? { top, right: left } : { top, left }
    const xsPosition = isArabic ? { top: xsTop, right: xsLeft } : { top: xsTop, left: xsLeft }

    return (
      <UILayout
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
        zIndex="100"
        position="absolute"
        width="295px"
        column
        borderRadius="2px"
        bgColor="white"
        padding="5px 15px"
        {...position}
        xs={xsPosition}
      >
        <UILayout borderBottom="3px solid #D4D4D4">
          <UIText align="start" font="ADPortsGroupBold, sans-serif" size="15px" color="#2D3955" text={title} />
        </UILayout>
        <UILayout padding="5px 0 0 0">
          <UIText align="start" size="13px" color="#2D3955" text={description} />
        </UILayout>
      </UILayout>
    )
  }
}

export default connect(
  (state) => ({
    locale: getLocale(state),
  }),
  null
)(InfoPopup)
