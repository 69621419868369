/* eslint-disable prefer-destructuring */
import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import messages from '../../../../consts/messages'
import UIElements from '../../../../consts/UIElements'
import { connect } from 'react-redux'
import { getLocale } from '../../../../services/user-selections'
import { menuItemsSelector } from '../../../../services/sales-static/selectors'
import { goToPage } from '../../../../actions'
import styled from 'styled-components'
import { translate } from '../../../Common/Translator'
import moduleStyles from './MaritimeFooterInfo.module.scss'
import { isTablet } from '../../../Common/components/AppBar'

export const FooterLink = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:visited {
    text-decoration: none;
    color: inherit;
  }
`

const MaritimeFooterLinks = ({ locale, menuItems, goToPage, footerData }) => {
  if (isTablet) {
    return (
      <UILayout
        bgColor="#29334D"
        column
        padding="20px 10px 10px 10px"
        j-flex-center
        width="100%"
        data-testid="admaritime-footer-links"
      >
        <UILayout center j-flex-center>
          <UIIcon type="iconFooterMobileLogo_admaritime" />
        </UILayout>

        <UILayout
          column
          margin="10px 0"
          style={{
            direction: locale === 'ar' ? 'rtl' : 'ltr',
            justifyContent: locale === 'ar' ? 'flex-start' : 'flex-end',
          }}
        >
          <UIText color="#C1CADE" margin="0 10px" size="14px">
            <span
              dangerouslySetInnerHTML={{
                __html: translate(messages.poweredByMaqta, {
                  currentYear: new Date().getFullYear(),
                }),
              }}
            ></span>
          </UIText>
        </UILayout>

        {menuItems.map((item) => (
          <UIText margin="0 10px" size="14px">
            <FooterLink
              className={moduleStyles.externalLink}
              href={item.link}
              onClick={(e) => {
                e.preventDefault()
                goToPage(item.link)
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            >
              {item.subject}
            </FooterLink>
          </UIText>
        ))}

        <UILayout j-flex-center margin="20px 0 0 0">
          {footerData.social.map((socialItem) => {
            if (socialItem.title) {
              return (
                <a href={socialItem.link} target="_blank" style={{ margin: '0 5px' }}>
                  <UIText text={socialItem.title} />
                </a>
              )
            }

            return null
          })}
        </UILayout>

        <UILayout
          row
          center
          style={{ ...(locale === 'ar' ? { flexDirection: 'row-reverse' } : {}) }}
          margin="20px 0 30px 0"
          j-flex-center
        >
          {footerData.social.map((socialItem) => {
            if (socialItem.title) {
              return null
            }

            return (
              <UILayout width="auto" margin="0 5px">
                <a href={socialItem.link} target="_blank">
                  <UIIcon type={socialItem.icon} />
                </a>
              </UILayout>
            )
          })}
        </UILayout>
      </UILayout>
    )
  }

  const adPorstHref = locale === 'ar' ? 'https://www.adportsgroup.com/ar' : 'https://www.adportsgroup.com/'

  return (
    <UILayout
      bgColor="#29334D"
      padding="35px"
      j-flex-center
      width="100%"
      data-testid="admaritime-footer-links"
      xs={{ padding: '20px 10px 10px 10px' }}
    >
      <UILayout
        smm={{ style: { flexDirection: 'column' } }}
        maxWidth={UIElements.APP_WIDTH}
        style={{ direction: locale === 'ar' ? 'rtl' : 'ltr' }}
      >
        {/* <UILayout center width="auto" smm={{ 'j-flex-center': true }}> */}
        {/*   <UIIcon type="iconFooterLogo_admaritime" /> */}
        {/* </UILayout> */}
        <UILayout width="30%" center smm={{ 'j-flex-center': true }} >
          <div className={moduleStyles.textLink}>
            <span>{translate(messages.footerPartOf)}</span>
            <a href={adPorstHref} target="_blank">
              {translate(messages.footerAdPortsGroup)}
            </a>
          </div>
        </UILayout>
        <UILayout column>
          <UILayout
            row
            center
            style={{ justifyContent: 'flex-end', ...(locale === 'ar' ? { flexDirection: 'row-reverse' } : {}) }}
            margin="0 0 30px 0"
            smm={{ margin: '20px 0 30px 0', style: { justifyContent: 'center' } }}
          >
            {footerData.social.map((socialItem) => {
              if (socialItem.title) {
                return (
                  <a href={socialItem.link} target="_blank" style={{ margin: '0 5px' }}>
                    <UIText text={socialItem.title} />
                  </a>
                )
              }

              return (
                <UILayout width="auto" margin="0 5px">
                  <a href={socialItem.link} target="_blank">
                    <UIIcon type={socialItem.icon} />
                  </a>
                </UILayout>
              )
            })}
          </UILayout>
          <UILayout
            row
            style={{
              direction: locale === 'ar' ? 'rtl' : 'ltr',
              justifyContent: locale === 'ar' ? 'flex-start' : 'flex-end',
            }}
            smm={{ flexDirection: 'column' }}
          >
            <UIText color="#C1CADE" margin="0 10px" size="14px">
              <span
                dangerouslySetInnerHTML={{
                  __html: translate(messages.poweredByMaqta, {
                    currentYear: new Date().getFullYear(),
                  }),
                }}
              ></span>
            </UIText>

            {menuItems.map((item) => (
              <UIText margin="0 10px" size="14px">
                <FooterLink
                  className={moduleStyles.externalLink}
                  href={item.link}
                  onClick={(e) => {
                    e.preventDefault()
                    goToPage(item.link)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }}
                >
                  {item.subject}
                </FooterLink>
              </UIText>
            ))}
          </UILayout>
        </UILayout>
      </UILayout>
    </UILayout>
  )
}

export default connect(
  (state) => ({
    locale: getLocale()(state),
    menuItems: (menuItemsSelector(state) || []).filter(({ line }) => line === 3),
  }),
  { goToPage }
)(MaritimeFooterLinks)
