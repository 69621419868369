import React from 'react'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'
import UIElements from '../../../../consts/UIElements'

const BluewaveFooter = () => {
  const currentYear = new Date().getFullYear()

  return (
    <UILayout
      j-flex-center
      style={{
        backgroundColor: '#01588B',
        height: '60px',
      }}
    >
      <UILayout center j-flex-space-between maxWidth={UIElements.APP_WIDTH}>
        <UIIcon type="iconFooterLogo_bluewave" />
        <UIText
          size="14px"
          color="#BCE7FC"
        >
          ©{currentYear} Blue Wave Corp.&nbsp;|&nbsp;
          <a href="https://bora.systems/" target="_blank">
            Powered by Bora Systems
          </a>
        </UIText>
      </UILayout>
    </UILayout>
  )
}

export default BluewaveFooter
