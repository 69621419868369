import { propOr } from 'ramda'

export const constructSitemapData = (footerData, menuItems, shouldFilterModalItems) => {
  const footerItems = propOr([], 'upper')(footerData)

  const footerItemsWithLinks = footerItems
    .map(({ title, content }) => ({
      title,
      links: content.filter(({ link }) => Boolean(link)),
    }))
    .filter(({ links }) => Boolean(links.length))

  const firstLineMenuItems = menuItems
    .filter(({ line }) => line === 1)
    .filter(({ modal }) => !(modal && shouldFilterModalItems))
    .map(({ subject, link, modal }) => ({ title: subject, link, modal }))

  const allMenuItems = [...footerItemsWithLinks, ...firstLineMenuItems]

  return allMenuItems.reduce(
    (sitemapData, menuItem, index) => {
      if (index % 2 === 0) {
        sitemapData.firstColumnSitemapItems.push(menuItem)
        return sitemapData
      }
      sitemapData.secondColumnSitemapItems.push(menuItem)
      return sitemapData
    },
    {
      firstColumnSitemapItems: [],
      secondColumnSitemapItems: [],
    }
  )
}
