import React from 'react'
import { UILayout, UIText } from 'bora-material-ui'
import UIElements from '../../../../consts/UIElements'

const styles = {
  copyrightWrapperXs: {
    center: true,
    column: true,
    opacity: 1,
  },
  footerTextMobile: {
    color: '#000000',
    fontWeight: '600',
  }
}

const KihnuFooter = () => {
  return (
    <UILayout
      data-testid="copyright"
      margin="20px"
      width={UIElements.APP_WIDTH}
      opacity="0.9"
      display-if={window.brandProps.theme === THEME.KIHNU}
      xs={styles.copyrightWrapperXs}
      pre-ms={styles.copyrightWrapperXs}
    >
      <UIText align="left" color="rgb(44, 70, 132)" font="GinesoNorm" xs={styles.footerTextMobile}>
        &copy; AS Kihnu Veeteed | Powered by &nbsp;
        <a href="https://bora.systems/" target="_blank">
          Bora Systems
        </a>
      </UIText>
    </UILayout>
  )
}

export default KihnuFooter
