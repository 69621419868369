import React from 'react'
import PropTypes from 'prop-types'
import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'
import moment from 'moment'
import { head } from 'ramda'
import ReactHtmlParser from 'react-html-parser'
import './NewsStyles.css'

import { FRIENDLY_DATE_FORMAT } from '../../../consts/stuff'
import { olderBrowser } from '../../../utils/browserUtils'
import { getMaritimeStyle } from '../../../utils/maritimeStyleUtils'
import { THEME } from 'bora-material-ui/themes'
import { isMobile } from '../../Common/components/AppBar'
import moduleStyles from './NewsItem.module.scss'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'

const titleStyle = {
  ...(olderBrowser && { marginLeft: '20px' }),
}

const expandIconType = window.brandProps.theme === THEME.LIINILAEVAD ? 'iconExpandLL' : 'iconChevron'
const fontFamily = window.brandProps.theme === THEME.LIINILAEVAD ? 'Mulish' : 'Roboto'

const getTitleTextColor = (muiTheme) => {
  return window.brandProps.theme === THEME.LIINILAEVAD ? muiTheme.palette.primary4Color : '#000000'
}

const getTitleFont = (muiTheme) => {
  return window.brandProps.theme === THEME.LIINILAEVAD ? muiTheme.secondFontFamily : 'Roboto'
}

class NewsItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = { showDescription: false }
  }

  toggleDescription = () => this.setState({ showDescription: !this.state.showDescription })

  render() {
    const { date, title, description, style, muiTheme, locale } = this.props

    const isArabic = locale === 'ar'
    const iconPosition = isArabic ? { top: '15px', left: '0' } : { top: '15px', right: '0' }

    const iconStyles =
      window.brandProps.theme === THEME.LIINILAEVAD
        ? {
            width: '10px',
            height: '15px',
            fill: '#FFFFFF',
            transform: this.state.showDescription ? 'rotate(180deg)' : 'rotate(0deg)',
          }
        : {
            width: '10px',
            height: '15px',
            fill: '#FFFFFF',
            transform: this.state.showDescription ? 'rotate(270deg)' : 'rotate(90deg)',
          }

    return (
      <UILayout column padding="40px 0" xs={{ padding: '20px 0' }} style={style}>
        <UILayout
          data-testid="news-item-inner"
          j-flex-end
          padding="0 0 15px"
          className={moduleStyles.direction}
          xs={{ column: true, padding: '0 0 10px', position: 'relative' }}
        >
          <UILayout column style={{ maxWidth: '110px' }}>
            <UIText
              color={`${window.brandProps.theme === THEME.LIINILAEVAD ? '#a8a8a8' : '#8aa1ac'}`}
              size="18px"
              xs={{ size: '14px', align: 'start' }}
              text={moment(date).format(FRIENDLY_DATE_FORMAT)}
              font={getMaritimeStyle('ADPortsGroupBold, sans-serif', fontFamily)}
              weight="normal"
              lineHeight="1.5"
            />
          </UILayout>
          <UILayout
            padding={getMaritimeStyle('0 20px')}
            column
            style={titleStyle}
            j-flex-start
            xs={{ padding: getMaritimeStyle('0'), style: { order: '4' } }}
          >
            <UIText
              color={getMaritimeStyle(this.state.showDescription ? '#13C5BD' : '#2D3955', getTitleTextColor(muiTheme))}
              size="18px"
              className={moduleStyles.text}
              xs={{ size: '14px' }}
              text={title}
              font={getMaritimeStyle('ADPortsGroupBold, sans-serif', getTitleFont(muiTheme))}
              weight="bold"
              lineHeight="1.5"
            />
          </UILayout>
          <UILayout
            column
            j-flex-start
            margin={isArabic ? '0 55px 0 0' : '0 0 0 55px'}
            width="max-content"
            xs={{ position: 'absolute', ...iconPosition }}
          >
            {description && (
              <UIButton
                type="circle"
                width="30px"
                height="30px"
                background={getMaritimeStyle('transparent', '#4266bd')}
                boxShadow={getMaritimeStyle('none')}
                fill={this.state.showDescription ? getMaritimeStyle('#13C5BD') : getMaritimeStyle('#2D3955')}
                xs={{ fill: '#0eb1bc', background: 'rgba(0,0,0,0)', align: 'right', boxShadow: 'unset' }}
                click={() => this.toggleDescription()}
              >
                <UIIcon type={expandIconType} margin="0 6px" style={iconStyles} />
              </UIButton>
            )}
          </UILayout>
        </UILayout>

        <div
          className={window.brandProps.theme === THEME.LIINILAEVAD && moduleStyles.content}
          style={getStyle({ marginLeft: isMobile && 0 })}
        >
          {this.state.showDescription &&
            head(
              ReactHtmlParser(
                `<div class="news-content" style="
                  line-height: 1.33;
                  padding: ${getMaritimeStyle(isMobile ? '5px 50px 0 0' : '0 50px 0 130px', '0 50px 0 130px')};
                  padding-inline-start: ${getMaritimeStyle(isMobile ? '0' : '130px', '130px')};
                  padding-inline-end: 50px;
                  margin-top: ${getMaritimeStyle('-15px', '-25px')};
                  font-family: ${getMaritimeStyle('ADPortsGroup, sans-serif', 'sans-serif')}
                ">
                  ${description}
                </div>`
              )
            )}
        </div>
      </UILayout>
    )
  }
}

NewsItem.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  style: PropTypes.object,
  muiTheme: PropTypes.object,
}

NewsItem.defaultProps = {
  style: {},
}

export default NewsItem
