import React from 'react'
import { UILayout, UIText } from 'bora-material-ui'
import messages from '../../../consts/messages'
import PropTypes from 'prop-types'
import { isLiinilaevad } from '../../../utils/liinilaevadThemeUtils'

function LegacyTicketHeader(props, context) {
  const { isLaak = false } = props

  if (isLiinilaevad) {
    return (
      <UILayout row center xs={0} minHeight="100px" {...context.muiTheme.ids.mainInside}>
        <UILayout row center j-flex-center padding="10px 20px">
          <UIText
            color="white"
            align="center"
            size="24px"
            font={context.muiTheme.secondFontFamily}
            translate={isLaak ? messages.editBookingNr : messages.editTicketNr}
          />
          <UIText color="white" align="center" size="24px" margin="0 0 0 8px" font={context.muiTheme.secondFontFamily}>
            {props.reservationId}
          </UIText>
        </UILayout>
      </UILayout>
    )
  }

  return (
    <UILayout margin="0 0 30px 0" center j-flex-center data-testid="edit-screen-title-legacy">
      <UIText
        color="#79909b"
        align="center"
        size="24px"
        font={context.muiTheme.secondFontFamily}
        translate={isLaak ? messages.editBookingNr : messages.editTicketNr}
      />
      <UIText color="#79909b" align="center" size="24px" margin="0 0 0 8px" font={context.muiTheme.secondFontFamily}>
        {props.reservationId}
      </UIText>
    </UILayout>
  )
}

LegacyTicketHeader.contextTypes = {
  muiTheme: PropTypes.object,
}

export { LegacyTicketHeader }
