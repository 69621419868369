import React from 'react'
import { connect } from 'react-redux'
import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'
import UIElements from '../../../../consts/UIElements'
import { LanguagePicker } from '../../../Language/components/Selects'
import { changeLocale, getLocale, getMobileMenuStatus } from '../../../../services/user-selections'
import { menuItemsSelector } from '../../../../services/sales-static/selectors'
import { goToPage, showModal } from '../../../../actions'
import s from '../../../Common/components/NavigationBar.module.scss'
import moduleStyles from './BluewaveNavigationBar.module.scss'
import { isMobile } from '../../../Common/components/AppBar'
import { styles } from '../../../Common/components/MaritimeNavigationBar'
import PropTypes from 'prop-types'
import cn from 'classnames'

const reloadWithLang = (locale = 'en') => {
  const { origin } = window.location

  if (typeof locale !== 'string') {
    return window.location.assign(origin)
  }

  const url = `${origin}/#/${locale}/`
  return window.location.assign(url)
}

const BluewaveNavigationBar = ({ locale, changeLocale, menuItems, goToPage }, { muiTheme }) => {

  const style = getComputedStyle(document.body)
  const firstLineItems = menuItems.filter(({ line }) => line === 1)
  const generateItemClickHandler = (link) => (e) => {
    e.preventDefault()
    goToPage(link)
  }

  const itemFsize = style.getPropertyValue('--fs-base-strong')

  const linkLabelStyles = {
    ...styles.linkLabel,
    color: muiTheme.palette.primary3Color,
    size: itemFsize
  }

  return (
    <UILayout
      j-flex-center
      style={{
        backgroundColor: '#FFF',
        height: '60px',
      }}
    >
      <UILayout center maxWidth={UIElements.APP_WIDTH} className={moduleStyles.rootBar}>
        <UIButton
          type="icon"
          width="auto"
          height="auto"
          disableRipple
          click={() => reloadWithLang(locale)}
        >
          <UIIcon type="iconHeaderLogo_bluewave" />
        </UIButton>

        <UILayout width="auto" center className={moduleStyles.itemsWrap}>
          {firstLineItems.map(({ link, id, subject, modal }) => {
            if (modal && isMobile) {
              return null
            }

            if (modal) {
              return (
                <UILayout key={id} {...styles.linkContainer}>
                  <span onClick={() => showModal(link)}>
                    <UIText {...linkLabelStyles} text={subject} />
                  </span>
                </UILayout>
              )
            }

            return (
              <UILayout key={id} {...styles.linkContainer} className={s.linkWrap}>
                <a href={link}
                   className={cn(s.linkElement, moduleStyles.navItem)} onClick={generateItemClickHandler(link)}>
                  <UIText {...linkLabelStyles} size={itemFsize} text={subject} />
                </a>
              </UILayout>
            )
          })}
        </UILayout>
        <div>
          <LanguagePicker withLabel locale={locale} onChange={changeLocale} />
        </div>
      </UILayout>
    </UILayout>
  )
}

BluewaveNavigationBar.contextTypes = {
  muiTheme: PropTypes.object,
}

export default connect(
  (state) => ({
    locale: getLocale()(state),
    menuItems: menuItemsSelector(state),
    mobileMenuOpened: getMobileMenuStatus(state),
  }),
  { changeLocale, goToPage, showModal }
)(BluewaveNavigationBar)
