import React, { useEffect, useState } from 'react'
import { UILayout } from 'bora-material-ui'
import { connect } from 'react-redux'
import moduleStyles from './Tariffs.module.scss'
import cn from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as R from 'ramda'
import { getTariffsRoot } from '../../../services/sales-static/api/tariffs-root'
import useSWR from 'swr'

const getFirstAvailableSlug = R.compose(R.prop('slug'), R.head, R.propOr([], 'tariffs'), R.defaultTo({}))

export const Tariffs = ({ locale = 'en' }) => {
  const { data, error } = useSWR(`tariffs-${locale}`, () => getTariffsRoot(locale))
  const [selectedSlug, selectSlug] = useState('')

  useEffect(() => {
    if (data) selectSlug(getFirstAvailableSlug(data))
  }, [data, selectSlug])

  const currentTable = data ? data.tariffs.find(({ slug }) => slug === selectedSlug) : {}

  if (!data || !currentTable) {
    return (
      <UILayout className={moduleStyles.loader}>
        <CircularProgress />
      </UILayout>
    )
  }

  if (error) {
    return <UILayout className={moduleStyles.loader}>Error loading tariffs, check the server connection!</UILayout>
  }

  const { subject, tariffs = [] } = data

  return (
    <UILayout className={moduleStyles.layout}>
      <div className={moduleStyles.heading}>{subject}</div>
      <ul className={moduleStyles.tabsList}>
        {tariffs.map((tariff) => (
          <li
            onClick={() => {
              selectSlug(tariff.slug)
            }}
            className={cn({ [moduleStyles.active]: selectedSlug === tariff.slug })}
          >
            {tariff.title}
          </li>
        ))}
      </ul>
      <div className={moduleStyles.description}>
        <span>{currentTable.Description || ''}</span>
      </div>
      <figure className={moduleStyles.wrapper}>
        <table>
          <tr className={moduleStyles.headRow}>
            {currentTable.data.cols.map(({ key, label }) => (
              <th key={key}>{label}</th>
            ))}
          </tr>
          {currentTable.data.rows.map((row, index) => (
            <tr key={index}>
              {Object.keys(row).map((key) => (
                <td key={key}>{row[key]}</td>
              ))}
            </tr>
          ))}
        </table>
      </figure>
    </UILayout>
  )
}

export default connect(({ locale }) => ({ locale }))(Tariffs)
