import { UILayout } from 'bora-material-ui'
import React from 'react'
import ReactDOM from 'react-dom'

const Overlay = (props) => {
  const { onClick } = props

  return ReactDOM.createPortal(
    <UILayout
      data-testid="overlay"
      onClick={onClick}
      position="fixed"
      top="0"
      zIndex={20}
      width="100%"
      height="100%"
      cursor="pointer"
    />,
    document.querySelector('#bora-app')
  )
}

export default Overlay
