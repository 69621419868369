import React, { Fragment } from 'react'
import { UIIcon, UILayout } from 'bora-material-ui'
import useSWR from 'swr'
import { getScheduleBySlug } from '../../../services/sales-static/api/sсhedules-root'
import CircularProgress from '@material-ui/core/CircularProgress'
import moduleStyles from './Schedule.module.scss'
import cn from 'classnames'
import { translate } from '../../Common/Translator'
import messages from '../../../consts/messages'
import { propOr } from 'ramda'

export const ScheduleDetails = ({ locale = 'en', slug = '', className = '' }) => {
  const { data, error } = useSWR(`schedule-${locale}-${slug}`, () => getScheduleBySlug(locale, slug))

  if (!data) {
    return (
      <UILayout className={moduleStyles.loader}>
        <CircularProgress />
      </UILayout>
    )
  }

  const [scheduleData] = data || []

  if (error) {
    return <UILayout className={moduleStyles.loader}>Error loading ferries schedules fro slug : {slug}</UILayout>
  }

  if (!scheduleData) {
    return (
      <UILayout className={moduleStyles.loader}>
        <CircularProgress />
      </UILayout>
    )
  }

  const iconNode = (
    <UIIcon type="iconLocation_admaritime" width="20px" height="20px" className={moduleStyles.locationIcon} />
  )

  return (
    <UILayout className={cn(className, moduleStyles.innerLayout)}>
      <div>{scheduleData.description}</div>
      {scheduleData.schedule.map((content) => {
        const [from, to] = content.direction

        let directionNode = (
          <div className={moduleStyles.direction}>
            <span className={moduleStyles.directionPoint}>
              {iconNode}
              {from}
            </span>
            <img alt="direction arrow down" src="https://www.admaritime.ae/wp-content/uploads/2022/02/Arrow.svg" />
            <span className={moduleStyles.directionPoint}>
              {iconNode}
              {to}
            </span>
          </div>
        )

        if (content.direction.length > 2) {
          directionNode = (
            <div className={cn(moduleStyles.direction, moduleStyles.taxi)}>
              {content.direction.map((entry) => (
                <span>
                  {iconNode}
                  {entry}
                </span>
              ))}
            </div>
          )
        }

        if (
          scheduleData.slug === 'yas-bay-public-water-transport' ||
          scheduleData.slug === 'yas-bay-llnql-almayy-aleam'
        ) {
          const isArabic = scheduleData.locale === 'ar'

          const DirectionItem = ({ title }) => {
            return (
              <div className={cn(moduleStyles.direction, { [moduleStyles.arabic]: isArabic })}>
                <UIIcon type="icon-table-arrow-down_admaritime" />
                <span className={moduleStyles.directionPoint}>
                  {iconNode}
                  {title}
                </span>
              </div>
            )
          }

          const { direction: directionTitles = [], options: [{ title = '', times = [], duration = '' }] = [] } = content
          const timeEntries = times.map(([time]) => time.split('-'))

          return (
            <div className={moduleStyles.taxiTable}>
              <h2 className={moduleStyles.title}>{title}</h2>

              <div className={moduleStyles.content}>
                <div className={cn(moduleStyles.row, moduleStyles.header)}>
                  <div className={moduleStyles.headerCell}>{translate(messages.scheduleTableHeadDirection)}</div>
                  <div className={moduleStyles.headerCell}>{translate(messages.scheduleTableHeadFirstDeparture)}</div>
                  <div className={moduleStyles.headerCell}>{translate(messages.scheduleTableHeadLatestDeparture)}</div>
                </div>

                <div className={moduleStyles.tableBody}>
                  <div className={cn(moduleStyles.arrowsWithDuration, { [moduleStyles.arabic]: isArabic })}>
                    <div className={moduleStyles.arrowsLine} />
                    <div className={cn(moduleStyles.durationItem, { [moduleStyles.arabic]: isArabic })}>
                      <div className={cn(moduleStyles.durationItemContent, { [moduleStyles.arabic]: isArabic })}>
                        <UIIcon type="icon-table-arrow-down_admaritime" />
                        <div className={moduleStyles.durationText} dangerouslySetInnerHTML={{ __html: duration }}></div>
                      </div>
                    </div>
                  </div>

                  {timeEntries.map(([startTime, endTime], id) => {
                    const currentDirectionTitle = propOr('', id)(directionTitles)
                    return (
                      <div className={moduleStyles.row}>
                        <div className={moduleStyles.directionCell}>
                          <DirectionItem title={currentDirectionTitle} />
                        </div>
                        <div className={moduleStyles.timeCell}>{startTime}</div>
                        <div className={moduleStyles.timeCell}>{endTime}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        }

        return (
          <div className={moduleStyles.table}>
            <div
              className={cn(moduleStyles.head, {
                [moduleStyles.fiveItemsHead]: content.withDuration && !content.withoutArrival,
              })}
            >
              <span>{translate(messages.scheduleTableHeadDirection)}</span>
              <span>{translate(messages.scheduleTableHeadDeparture)}</span>
              {!content.withoutArrival && <span>{translate(messages.scheduleTableHeadArrival)}</span>}
              {content.withDuration && <span>{translate(messages.scheduleTableHeadDuration)}</span>}
              <span>{translate(messages.scheduleTableHeadDays)}</span>
            </div>
            {directionNode}
            <div
              className={cn(moduleStyles.timetable, {
                [moduleStyles.withDuration]: content.withDuration,
                [moduleStyles.withoutArrivalCol]: content.withoutArrival,
              })}
            >
              {content.options.map((option) => (
                <Fragment>
                  <div className={moduleStyles.timeRows}>
                    {option.times.map(([t1, t2]) => (
                      <div
                        className={cn({
                          [moduleStyles.useSmallerFont]: content.withDuration,
                        })}
                      >
                        <span>{t1}</span>
                        {t2 && <span>{t2}</span>}
                      </div>
                    ))}
                  </div>
                  {content.withDuration && (
                    <div className={cn(moduleStyles.days)} dangerouslySetInnerHTML={{ __html: option.duration }}></div>
                  )}
                  <div className={moduleStyles.days}>{option.title}</div>
                </Fragment>
              ))}
            </div>
          </div>
        )
      })}
    </UILayout>
  )
}
