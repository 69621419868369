import axios from 'axios'
import { UILayout } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import useSWR from 'swr'
import { goToPage, showModal } from '../../../actions'
import messages from '../../../consts/messages'
import { getIsTablet } from '../../../reducers/screenWidth'
import { menuItemsSelector } from '../../../services/sales-static/selectors'
import { getLocale } from '../../../services/user-selections'
import { translate } from '../../Common/Translator'
import moduleStyles from './Sitemap.module.scss'
import SitemapItem from './SitemapItem'
import { constructSitemapData } from './utils'

const Sitemap = ({ locale, menuItems, isTablet, goToPage, showModal }) => {
  const { data: footerData } = useSWR(`static-footer-${locale}`, () =>
    axios('/api/sales-static/footer', {
      params: { locale },
    })
  )

  if (!footerData) return null

  const sitemapItems = constructSitemapData(footerData, menuItems, isTablet)
  const { firstColumnSitemapItems, secondColumnSitemapItems } = sitemapItems

  const renderSitemapItem = ({ title, link, links, modal = false }) => (
    <SitemapItem
      key={link}
      title={title}
      links={links}
      link={link}
      modal={modal}
      goToPage={goToPage}
      showModal={showModal}
    />
  )

  return (
    <UILayout className={moduleStyles.layout}>
      <div className={moduleStyles.heading}>{translate(messages.sitemapTitle)}</div>
      <UILayout xs={{ column: true }}>
        <UILayout padding="0 15px" column>
          {firstColumnSitemapItems.map(renderSitemapItem)}
        </UILayout>
        <UILayout padding="0 15px" column>
          {secondColumnSitemapItems.map(renderSitemapItem)}
        </UILayout>
      </UILayout>
    </UILayout>
  )
}

const mapStateToProps = (state) => {
  return {
    isTablet: getIsTablet(state),
    locale: getLocale(state),
    menuItems: menuItemsSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    goToPage: (path) => dispatch(goToPage(path)),
    showModal: (modalName) => dispatch(showModal(modalName)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sitemap)
