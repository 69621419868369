import messages from '../../../consts/messages'
import Payment from '../../Booking/containers/MainInside/Steps/ConfirmPay/Payment'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import { actions, selectors } from '../../Booking/provider'
import { pathOr } from 'ramda'
import { getReservation } from '../EditTicket/editTicketSelectors'
import { isLaaksaareSail } from '../../../utils/laaksaare'
import { isCreditCard } from '../../Booking/containers/MainInside/Steps/ConfirmPay/ConfirmPay'
import { creditCardPaymentComplete } from '../../../services/payment/actions'
import { getBookingConditionsForRoute } from '../../../services/reservation/selectors'
import { getStyle, isLiinilaevad } from '../../../utils/liinilaevadThemeUtils'

const getEmailFromReservation = pathOr('', ['reservationOwner', 'email'])
const getFullNameFromReservation = pathOr('', ['reservationOwner', 'fullName'])
const getLocaleFromReservation = pathOr('en', ['language'])

function LegacyFooterView(props, context) {
  const {
    differenceToPay,
    goBackAndCancel,
    reservation,
    sailPackageCode,
    userNeedToPayAfterEdit,
    creditCardPaymentComplete,
    outOfBookingBoundary,
  } = props

  const handlePayment = (paymentCode) => {
    const { paymentInfo, reservationId, token } = reservation

    if (isCreditCard(paymentCode)) {
      const {
        totalPrice: { currency, amount },
      } = paymentInfo

      window.creditCardCallback = function creditCardCallback(data) {
        creditCardPaymentComplete({ ...data, reservationId })
      }

      props.prepareForPayment({
        reservationToken: token,
        paymentMethod: paymentCode,
        creditCardPayment: true,
        ccDialogData: {
          amount,
          currency,
          email: getEmailFromReservation(reservation),
          clientName: getFullNameFromReservation(reservation),
          locale: getLocaleFromReservation(reservation),
          name: window.brandProps.official.title,
          description: `Reservation ${reservationId}`, // todo: add translation messages.checkinReservationId
          completed: 'creditCardCallback',
        },
      })

      return
    }

    if (paymentCode !== 'INVOICE') {
      props.prepareForPayment({
        reservationToken: token,
        paymentMethod: paymentCode,
      })
    } else {
      props.pay({
        reservationToken: token,
        paymentMethod: 'INVOICE',
        amount: paymentInfo.totalPrice.amount,
      })
    }
  }
  const isLaak = isLaaksaareSail(reservation.sailPackages) && !outOfBookingBoundary
  const { muiTheme } = context
  return (
    <Fragment>
      <UILayout data-testid="payment-process" display-if={userNeedToPayAfterEdit} style={{ paddingTop: '14px' }}>
        <Payment amount={differenceToPay} editMode onPaymentClick={handlePayment} sailPackageCode={sailPackageCode} />
      </UILayout>

      <UILayout
        data-testid="edit-mode-cancel-link"
        center={!isLiinilaevad}
        j-flex-center={!isLiinilaevad}
        margin={getStyle('20px 20px 45px auto', '24px 0')}
        onClick={() => goBackAndCancel(reservation.token)}
        style={{
          ...(muiTheme.ids.buttonActionStyles && {
            ...muiTheme.ids.buttonActionStyles.backButton,
            ...muiTheme.ids.buttonActionStyles.cancelButton,
          }),
        }}
        j-flex-end
        xs={{
          margin: getStyle('20px auto 45px auto'),
        }}
      >
        <UILayout data-testid="cancel-button" width="auto" center j-flex-center cursor="pointer">
          <UIIcon
            fill={getStyle('#585858', '#79909B')}
            height="24px"
            width="29px"
            type="iconGoBack"
            margin="0 20px 0 0"
          />
          <UIText
            size="18px"
            textTransform="uppercase"
            font={context.muiTheme.secondFontFamily}
            color={getStyle('#585858', '#79909b')}
            translate={isLaak ? messages.cancelAndBackLaak : messages.canselAndBack}
          />
        </UILayout>
      </UILayout>
    </Fragment>
  )
}

LegacyFooterView.contextTypes = {
  muiTheme: PropTypes.object,
}

function mapStateToProps(state) {
  const reservation = getReservation(state)
  const editReservation = selectors.getEditReservation(state)
  const originalReservation = pathOr('', ['reservation', 'edit', 'originalReservation'], state)
  const trips = selectors.tripsFromReservation(state)(editReservation)
  const firstTrip = pathOr({}, [[0]], trips)
  const sailPackageCode = pathOr('', ['sailRefs', [0], 'route'], firstTrip)
  const amount = pathOr(0, ['paymentInfo', 'totalPrice', 'amount'], originalReservation)
  const newAmount = pathOr(0, ['paymentInfo', 'totalPrice', 'amount'], editReservation)
  const differenceToPay = newAmount - amount
  const { userNeedToPayAfterEdit } = state

  return {
    reservation,
    differenceToPay,
    originalReservation,
    sailPackageCode,
    userNeedToPayAfterEdit,
    outOfBookingBoundary: getBookingConditionsForRoute(state),
  }
}

const mapDispatchToProps = {
  goBackAndCancel: actions.cancelEditReservation,
  prepareForPayment: actions.prepareForPayment,
  pay: actions.pay,
  creditCardPaymentComplete,
}

const LegacyTicketFooter = connect(mapStateToProps, mapDispatchToProps)(LegacyFooterView)

export { LegacyTicketFooter }
