import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { compose, head, keys, pathOr } from 'ramda'

import messages from '../../../consts/messages'
import {
  discountReducer,
  findWeight,
} from '../../../modules/Booking/containers/MainInside/Steps/ConfirmPay/ConfirmationInfo/TripTicket'
import { CustomWeightControl } from '../../Booking/containers/MainInside/Steps/ConfirmPay/ConfirmationInfo/Layout'
import { THEME } from 'bora-material-ui/themes'
import { LLDiscountSummary } from '../../Booking/forms/TicketsForm/components/LLTicketPromotionLine'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'

const TableWrapper = styled.div`
  @media (max-width: 840px) {
    display: flex;
    overflow-x: scroll;
  }
`

const Wrapper = styled.table`
  line-height: 32px;
  table-layout: fixed;
  width: 100%;
  font-family: ${getStyle('SourceSansPro, Sans-Serif')};
  font-size: ${getStyle('15px', '16px')};

  @media (max-width: 840px) {
    min-width: 350px;
  }

  & > tr > th {
    color: #bfc3c7;
    font-weight: normal;
  }

  & > tr > td:first-child,
  & > tr > th:first-child {
    text-align: left;
    width: 50%;
  }

  & > tr > td:not(:first-child),
  & > tr > th:not(:first-child) {
    text-align: center;
  }
`

class ReservationItemsView extends PureComponent {
  render() {
    const { intl, items, customWeightMap, vehicles, setModalData, showModal } = this.props

    const weightFinder = findWeight(vehicles)

    const discountSummaryObj = window.brandProps.theme !== THEME.LIINILAEVAD ? null : items.reduce(discountReducer, {})

    return (
      <TableWrapper>
        <Wrapper data-testid="items-table">
          <tr>
            <th>{intl.formatMessage(messages.ticketType)}</th>
            <th>{intl.formatMessage(messages.ticketQtty)}</th>
            <th>{intl.formatMessage(messages.ticketPrice)}</th>
            <th>{intl.formatMessage(messages.ticketSubtotal)}</th>
          </tr>
          {items.map((item) => {
            const customWeightPossibility = customWeightMap[item.priceCategory || '']
            const seqNum = pathOr(0, ['ownerSeqNs', 0])(item)
            const sailRefId = compose(head, keys)(item.sailRefIdToPricePerSail)
            const { custom = 0, former = 0 } = weightFinder(seqNum, parseInt(sailRefId, 10))

            const vehicleData = {
              seqNum,
              sailRefId,
              customWeight: custom,
              formerWeight: former,
            }

            if (item.promotion && window.brandProps.theme === THEME.LIINILAEVAD) {
              return null
            }

            return (
              <tr>
                <td>
                  {item.priceCategoryTranslation}
                  <CustomWeightControl
                    display-if={customWeightPossibility}
                    vehicleData={vehicleData}
                    setModalData={setModalData}
                    showModal={showModal}
                    customWeightPossibility={customWeightPossibility}
                  />
                </td>
                <td>{item.promotion ? '' : item.quantity}</td>
                <td>{this.formatPrice(item.pricePerUnit)}</td>
                <td>{this.formatPrice(item.price)}</td>
              </tr>
            )
          })}
          <LLDiscountSummary editView shouldShow={discountSummaryObj} promoDiscountSummary={discountSummaryObj} />
        </Wrapper>
      </TableWrapper>
    )
  }

  formatPrice(value) {
    const { currency, intl, hideZeroPrice = false } = this.props

    if (hideZeroPrice && value <= 0) return ''

    return intl.formatNumber(value, {
      currency,
      minimumFractionDigits: 0,
      style: 'currency',
    })
  }
}

export { ReservationItemsView }
