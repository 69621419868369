import React, { useEffect, useState } from 'react'
import { UILayout } from 'bora-material-ui'
import useSWR from 'swr'
import { getScheduleRoot } from '../../../services/sales-static/api/sсhedules-root'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import moduleStyles from './Schedule.module.scss'
import cn from 'classnames'
import { ScheduleDetails } from './ScheduleDetails'
import * as R from 'ramda'

const sortBySortOrder = R.sortBy(R.prop('sortOrder'))

const getFirstAvailableSlug = R.compose(
  R.prop('slug'),
  R.head,
  sortBySortOrder,
  R.propOr([], 'schedules'),
  R.defaultTo({})
)

export const Schedule = ({ locale = 'en' }) => {
  const { data, error } = useSWR(`schedule-${locale}`, () => getScheduleRoot(locale))
  const [selectedSlug, selectSlug] = useState('')

  useEffect(() => {
    if (data) selectSlug(getFirstAvailableSlug(data))
  }, [data, selectSlug])

  if (!data) {
    return (
      <UILayout className={moduleStyles.loader}>
        <CircularProgress />
      </UILayout>
    )
  }

  if (error) {
    return (
      <UILayout className={moduleStyles.loader}>Error loading ferries schedules, check the server connection!</UILayout>
    )
  }

  const { subject, schedules } = data

  return (
    <UILayout className={moduleStyles.layout}>
      <div className={moduleStyles.heading}>{subject}</div>
      <ul className={moduleStyles.tabsList}>
        {sortBySortOrder(schedules).map((schedule) => (
          <li
            onClick={() => {
              selectSlug(schedule.slug)
            }}
            className={cn({ [moduleStyles.active]: selectedSlug === schedule.slug })}
          >
            {schedule.title}
          </li>
        ))}
      </ul>
      <ScheduleDetails className={moduleStyles.details} locale={locale} slug={selectedSlug} />
    </UILayout>
  )
}

export default connect(({ locale }) => ({ locale }))(Schedule)
