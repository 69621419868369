import { UILayout, UIText } from 'bora-material-ui'
import React from 'react'

class SitemapItem extends React.Component {
  render() {
    const { title, link, links = [], modal, goToPage, showModal } = this.props

    const renderLink = ({ title, link }) => {
      return (
        <UILayout margin="10px 15px" key={link}>
          <UILayout width="auto" onClick={() => goToPage(link)} style={{ cursor: 'pointer' }}>
            <UIText color="#63666A" size="14px" text={title} />
          </UILayout>
        </UILayout>
      )
    }

    return (
      <UILayout column>
        <UILayout
          margin="10px 0"
          padding="6px"
          bgColor="#e9e9e7"
          onClick={() => {
            if (link && modal) {
              showModal(link)
              return
            }
            if (link) {
              goToPage(link)
            }
          }}
          style={link ? { cursor: 'pointer' } : {}}
        >
          <UIText font="ADPortsGroupBold, Sans-Serif" color="#2d3955" size="16px" text={title} />
        </UILayout>
        <UILayout column>{links.map(renderLink)}</UILayout>
      </UILayout>
    )
  }
}

export default SitemapItem
