import React, { PureComponent } from 'react'
import { getFormSyncErrors } from 'redux-form'
import { compose } from 'ramda'

import styled from 'styled-components'
import { UIText } from 'bora-material-ui'
import messages from '../../../consts/messages'
import { connect } from 'react-redux'

const Wrapper = styled.table`
  line-height: 32px;
  table-layout: fixed;
  width: 85%;
  text-align: left;

  & > tr {
    vertical-align: text-top;
  }

  & > tr > th {
    color: #bfc3c7;
    font-weight: normal;
  }

  & > tr > td:first-child,
  & > tr > th:first-child {
    text-align: left;
    color: #bfc3c7;
    width: 40%;
    word-break: break-word;
  }

  & > tr > td:last-child,
  & > tr > th:last-child {
    text-align: left;
    max-width: 60%;
    padding-right: 10px;
    padding-left: 10px;
    word-break: break-word;
  }
`

class PassengerDetailsView extends PureComponent {
  render() {
    const { firstName, lastName, ticketType, personalCode, birthday, citizenship } = this.props

    const renderName = () => {
      if (firstName && lastName && birthday && citizenship) {
        return `${firstName} ${lastName}`
      }

      if (personalCode && citizenship) {
        return null
      }

      return <UIText color="#d0021b" size="16px" align="left" translate={messages.passengerEmptyDataNotification} />
    }

    return (
      <Wrapper data-testid="passengers-table">
        <tr>
          <td>
            <div style={{ color: '#20bbd1' }}>{personalCode ? personalCode : ''}</div>
            {ticketType}
          </td>
          <td>{renderName()}</td>
        </tr>
      </Wrapper>
    )
  }
}

function mapStateToProps(state) {
  return {
    formSyncErrors: getFormSyncErrors('editPassenger')(state) || {},
  }
}

const PassengerDetails = compose(connect(mapStateToProps, null))(PassengerDetailsView)

export { PassengerDetails }
