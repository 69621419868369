/* eslint-disable complexity */
import { UILayout, UIText } from 'bora-material-ui'
import React, { Fragment } from 'react'
import UIElements from '../../../../consts/UIElements'
// import MaritimeFooterEmailInput from './Components/MaritimeFooterEmailInput'
import MaritimeFooterInfoCard from './Components/MaritimeFooterInfoCard'
import { FooterLink } from './MaritimeFooterLinks'
import { connect } from 'react-redux'
import cn from 'classnames'
import moduleStyles from './MaritimeFooterInfo.module.scss'
import { isMobile } from '../../../Common/components/AppBar.js'
import { formatPhone } from '../../../../utils/formatPhone'

const textStyle = { textAlign: 'start', whiteSpace: 'nowrap' }
const circleStyle = { position: 'absolute', borderRadius: '50%', border: '1px solid #14D1C9' }

const renderNode =
  (locale, goToPage, authToken, scrollRef) =>
  ({ title = '', linkType = '', link = '', decoration = '', auth, font = '16px', withBottomMargin = false }) => {
    const isTel = linkType === 'tel' && typeof title === 'string'
    const isBolder = decoration === 'bold'
    const isMultipleTitle = typeof title === 'object'
    const style = {
      ...(isMultipleTitle && { justifyContent: 'space-between' }),
      ...(isTel &&
        locale === 'ar' && {
          direction: 'ltr',
          flexDirection: 'row-reverse',
        }),
    }

    if (auth === 'hide' && authToken) return null
    if (auth === 'show' && !authToken) return null

    let textNode = (
      <UIText
        size={font}
        margin={withBottomMargin ? '0 0 20px' : '0'}
        font={isBolder ? 'ADPortsGroupBold, sans-serif' : 'ADPortsGroup, sans-serif'}
      >
        {link ? (
          <FooterLink
            href={link}
            className={cn({ [moduleStyles.externalLink]: link.startsWith('https://') })}
            target={link.startsWith('https://') ? '_blank' : '_self'}
            onClick={(e) => {
              if (link.startsWith('https://') || link.startsWith('mailto')) return

              e.preventDefault()

              if (link === ':scroll') {
                goToPage('/')
                if (scrollRef && scrollRef.current) {
                  scrollRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: isMobile ? 'start' : 'center',
                  })
                }
              } else {
                goToPage(link)
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              }
            }}
          >
            {title}
          </FooterLink>
        ) : (
          <span style={textStyle}>{linkType === 'tel' ? formatPhone(title) : title}</span>
        )}
      </UIText>
    )

    if (isMultipleTitle) {
      textNode = title.map((t, i) => {
        const linkNode = <a className={moduleStyles.telLink} href={`tel:${formatPhone(t, false)}`}>{formatPhone(t, false)}</a>
        return (
          <UIText
            className={cn({ [moduleStyles.telNode]: i > 0 && linkType === 'tel' })}
            font={i > 0 ? 'ADPortsGroupBold' : 'ADPortsGroup'}
          >{i > 0 && linkType === 'tel' ? linkNode : t}</UIText>
        )
      })

      if (link) {
        const [part1, part2] = title
        textNode = (
          <Fragment>
            <UIText text={part1} font="ADPortsGroup" />
            <UIText>
              <FooterLink target={link.startsWith('https://') ? '_blank' : '_self'} font="ADPortsGroupBold" href={link}>
                {part2}
              </FooterLink>
            </UIText>
          </Fragment>
        )
      }
    }

    return <UILayout style={style}>{textNode}</UILayout>
  }

const getDeckStylesForLocale = () => ({ justifyContent: 'flex-start', gap: '0' })

const MaritimeFooterInfo = ({ footerScrollRef, locale, goToPage, auth, footerData = { upper: [], lower: [] } }) => {
  return (
    <UILayout
      bgColor="#2D3955"
      padding="35px"
      j-flex-center
      width="100%"
      data-testid="admaritime-footer-main"
      position="relative"
      overflow="hidden"
      xs={{ padding: '35px 4px 4px 4px' }}
    >
      <UILayout
        {...circleStyle}
        width="800px"
        height="800px"
        bottom="-400px"
        {...(locale === 'ar' ? { left: '-400px', right: 'auto' } : { right: '-400px', left: 'auto' })}
      />
      <UILayout
        {...circleStyle}
        width="500px"
        height="500px"
        bottom="-250px"
        {...(locale === 'ar' ? { left: '-250px', right: 'auto' } : { right: '-250px', left: 'auto' })}
      />

      <UILayout maxWidth={UIElements.APP_WIDTH} column>
        <UILayout style={getDeckStylesForLocale(locale)} margin="0 0 20px 0" wrap>
          {footerData.upper.map((item, index) => (
            <MaritimeFooterInfoCard title={item.title} locale={locale} key={item.title} wider={index === 0}>
              {item.content.map(renderNode(locale, goToPage, auth, footerScrollRef))}
            </MaritimeFooterInfoCard>
          ))}
        </UILayout>

        <UILayout style={getDeckStylesForLocale(locale)} wrap>
          {footerData.lower.map((item, index) => (
            <MaritimeFooterInfoCard icon={item.icon} locale={locale} key={item.title} wider={index === 0}>
              {item.content.map(renderNode(locale, goToPage, auth, footerScrollRef))}
            </MaritimeFooterInfoCard>
          ))}
        </UILayout>
      </UILayout>
    </UILayout>
  )
}

export default connect((s) => {
  return {
    footerScrollRef: s.scroll,
  }
}, null)(MaritimeFooterInfo)
