import { UILayout } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import { getLocale } from '../../../../services/user-selections'
import MaritimeFooterInfo from './MaritimeFooterInfo'
import MaritimeFooterLinks from './MaritimeFooterLinks'
import useSWR from 'swr'
import axios from 'axios'
import { goToPage } from '../../../../actions'
import { getToken } from '../../../../services/auth/reducers/selectors'
import moduleStyles from '../../../Modal/Modals.module.scss'

const MaritimeFooter = ({ locale, goToPage, auth }) => {
  const { data: footerData } = useSWR(`static-footer-${locale}`, () =>
    axios('/api/sales-static/footer', {
      params: { locale },
    })
  )

  if (!footerData) return null

  return (
    <UILayout
      data-testid="footer-wrapper"
      zIndex="99"
      width="100vw"
      column
      className={`${moduleStyles.formDirection} non-printable`}
    >
      <MaritimeFooterInfo locale={locale} footerData={footerData} goToPage={goToPage} auth={auth} />
      <MaritimeFooterLinks locale={locale} footerData={footerData} />
    </UILayout>
  )
}

export default connect(
  (state) => ({
    locale: getLocale(state),
    auth: getToken(state),
  }),
  { goToPage }
)(MaritimeFooter)
